export const CHANGE_WAYPOINT = "CHANGE_WAYPOINT";
export const CHARACTER_LOADED = "CHARACTER_LOADED";

export const onEnterWaypoint = (updates) => ({
  type: CHANGE_WAYPOINT,
  character: updates.character,
  selectedWorksHeader: updates.selectedWorksHeader,
  background: updates.background,
  waypointOffset: updates.waypointOffset
})

export const onCharacterLoaded = (updates) => ({
  type: CHARACTER_LOADED,
  initialized: true
})