module.exports = [{
      plugin: require('C:/Users/bisse/workspace/GitHub/johanbissemattsson/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/bisse/workspace/GitHub/johanbissemattsson/node_modules/gatsby-plugin-glamor/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/bisse/workspace/GitHub/johanbissemattsson/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-10349335-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('C:/Users/bisse/workspace/GitHub/johanbissemattsson/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
