import { combineReducers } from 'redux'
import { CHANGE_WAYPOINT, CHARACTER_LOADED } from '../actions'

const Waypoint = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_WAYPOINT:
      return {
        ...state,
        character: action.character,
        selectedWorksHeader: action.selectedWorksHeader,
        background: action.background,
        waypointOffset: action.waypointOffset
      }
    default:
      return state;
  }
}


const Character = (state = {}, action) => {
  switch (action.type) {
    case CHARACTER_LOADED:
      return {
        ...state,
        initialized: action.initialized
      }
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  Waypoint,
  Character
})

export default rootReducer